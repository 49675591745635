<template>
  <div>
    <v-card class="pa-0 tab-item-card" flat>
      <v-img class="section-title-bg-img" src="../../assets/deposit.jpg"></v-img>
      <div class="text-center py-12">
        <span class="text-h3 text-sm-h2 text-md-h1">Deposit scheme</span>
      </div>
      <v-container class="px-5 py-5 py-sm-12">
        <p>Deposit</p>
        <p>7.1 The sum of xxxx shall be paid by The tenant, (‘The Relevant Person’) to the Landlord/Agent upon the signing of this Agreement by way of a security Deposit (‘the Deposit’)</p>
        <p>7.2 Upon the Tenant vacating the Premises and after deduction of all agreed or authorised deductions, the balance of the Deposit shall be refunded to the person or persons outlined at clause 7.1 above</p>
        <p class="ml-5 font-weight-light">7.2.1 The Deposit will be held by the Agent as Stakeholder. The Agent is a member of Tenancy Deposit Scheme</p>
        <p>7.3 The Landlord’s Agent will protect the Deposit within 30 days of the commencement of the Tenancy or receipt of the Deposit, whichever is earlier, and give to the Tenant and to any Relevant Person a copy of the Prescribed Information together with details of the scheme applicable to the registration of the Deposit</p>
        <p>7.4 Any interest earned on the holding of the Deposit will belong to Thomas Morris Property Management</p>
        <p>7.5 The Deposit has been taken for the following purposes:</p>
        <p class="ml-5 font-weight-light">7.5.1 Any fees or other monies that the Agent is entitled to recover from the Tenant</p>
        <p class="ml-5 font-weight-light">7.5.2 Any rent or other money due or payable by the Tenant under the Tenancy of which the Tenant has been made aware and which remains unpaid after the end of the Tenancy. This will include a fee which any Agent is entitled to recover from the Tenant</p>
        <v-card class="red darken-4 pa-3 mt-6" outlined flat>
          <v-card-text class="white py-3">
            <v-card outlined flat class="pa-4">
              <v-radio v-model="payDeposit" class="my-6 font-weight-bold" inset label="Pay cash deposit of £750 to be registered with the TDS deposit scheme by the agent?"></v-radio>
              <span class="text-body-1 font-weight-bold">Take a deposit insurance policy with</span>
              <v-radio-group v-model="depositScheme">
                <v-radio
                  id="1"
                  class=""
                  label="Zero Deposits policy for one weeks rent totalling £161"
                ></v-radio>
                <v-radio
                  id="2"
                  label="Flat Fair policy for one weeks rent totalling £161"
                ></v-radio>
                <v-radio
                  id="2"
                  label="Reposit policy for one weeks rent totalling £161"
                ></v-radio>
              </v-radio-group>
            </v-card>
          </v-card-text>
        </v-card>
      </v-container>
      <div class="red darken-4 text-center py-12">
        <v-container class="px-5 py-8">
          <p class="white--text text-h5 text-sm-h4">The deposit alternative insurance products we offer, the agent will receive a one off commission from you purchasing a policy.</p>
        </v-container>
      </div>
    </v-card>
    <MainPagination 
      previousPage="/review/utilities"
      nextPage="/review/deposit-info"
    />
  </div>
</template>

<script>
export default {
  name: 'agreement-deposit-scheme'
}
</script>

<style>

</style>